<template>
  <div class="processes col-11 col-sm-10">
    <div
      class="processes__title"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      {{ title }}
    </div>
    <Slider :data="dataSlider" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
const Slider = () => import('../Components/Slider.vue');

export default {
  name: 'Process',
  data() {
    return {
      length: 3,
      title: this.$t('home').process.title,
      cards: this.$t('home').process.cards,
      dataSlider: [
        {
          icon: '/images/icons/home/analysis.svg',
          background: '/images/home/step1.webp',
          step: this.$t('home').process.cards[0].step,
          content: this.$t('home').process.cards[0].content
        },
        {
          icon: '/images/icons/home/develop.svg',
          background: '/images/home/step2.webp',
          step: this.$t('home').process.cards[1].step,
          content: this.$t('home').process.cards[1].content
        },
        {
          icon: '/images/icons/home/test.svg',
          background: '/images/home/step3.webp',
          step: this.$t('home').process.cards[2].step,
          content: this.$t('home').process.cards[2].content
        },
        {
          icon: '/images/icons/home/manual-test.svg',
          background: '/images/home/step4.webp',
          step: this.$t('home').process.cards[3].step,
          content: this.$t('home').process.cards[3].content
        },
        {
          icon: '/images/icons/home/review.svg',
          background: '/images/home/step5.webp',
          step: this.$t('home').process.cards[4].step,
          content: this.$t('home').process.cards[4].content
        }
      ],
      window: 0,
      activeTab: 0
    };
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    }
  },
  mounted() {},
  watch: {
    lang() {
      this.title = this.$t('home').process.title;
      this.cards = this.$t('home').process.cards;
      this.dataSlider = [
        {
          icon: '/images/icons/home/analysis.svg',
          background: '/images/home/step1.webp',
          step: this.$t('home').process.cards[0].step,
          content: this.$t('home').process.cards[0].content
        },
        {
          icon: '/images/icons/home/develop.svg',
          background: '/images/home/step2.webp',
          step: this.$t('home').process.cards[1].step,
          content: this.$t('home').process.cards[1].content
        },
        {
          icon: '/images/icons/home/test.svg',
          background: '/images/home/step3.webp',
          step: this.$t('home').process.cards[2].step,
          content: this.$t('home').process.cards[2].content
        },
        {
          icon: '/images/icons/home/manual-test.svg',
          background: '/images/home/step4.webp',
          step: this.$t('home').process.cards[3].step,
          content: this.$t('home').process.cards[3].content
        },
        {
          icon: '/images/icons/home/review.svg',
          background: '/images/home/step5.webp',
          step: this.$t('home').process.cards[4].step,
          content: this.$t('home').process.cards[4].content
        }
      ];
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  components: { Slider }
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}

.processes {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 50px 0;
  z-index: 0;
  .processes__title {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: var(--blue-text);
    text-align: center;
    z-index: 0;
    margin-bottom: 80px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 46%;
    left: -8%;
    background-color: var(--orange-background);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    z-index: -1;
  }
  @media (max-width: 767.98px) {
    & {
      padding: 0;
    }
    .processes__title {
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
}
</style>
